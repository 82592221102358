import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import './default-preset/preset'
import fr from '../../i18n/fr'
import en from '../../i18n/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr, en },
    current: navigator.language.includes('fr') ? 'fr':'en'
  },
  //preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: true,
    },
    light: {
      primary: '#3BAFDD',
    }
  },
})
