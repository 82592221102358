export default {
  "global":{
    "title": 'ACS Business Center'
  },
  "dataIterator": {
    "rowsPerPageText": "Items per page:",
    "rowsPerPageAll": "All",
    "pageText": "{0}-{1} of {2}",
    "noResultsText": "No matching records found",
    "nextPage": "Next page",
    "prevPage": "Previous page"
  },
  "dataTable": {
    "rowsPerPageText": "Rows per page:"
  },
  "login": {
    "email":"Adresse e-mail",
    "password":"Mot de passe",
    "forgetPassword":"Mot de passe oublié?",
    "login":"Connexion",
    "alertMessage": 'Il est possible que vous ayez des onglets ouverts et déjà connectés à ABC dans votre navigateur. Pouvez-vous les vérifier et les fermer avant de vous connecter.',
    "emailValidity": 'E-mail doit être valide',
    "emailRequired": 'Saisissez votre adresse e-mail',
    "passwordRequired": 'Saisissez votre mot de passe',
    "internalError":'Une erreur est survenue. Merci de réessayer ultérieurement',
    "incorrectMessage": 'E-mail et / ou le mot de passe est incorrect',
    "LOCKED_USER": 'ABC à rencontré un problème avec la configuration de votre compte - Merci de contacter ACS',
    "USER_NOT_ALLOWED": "ABC à rencontré un problème avec la configuration de votre compte - Merci de contacter ACS",
    "REF_CONTACT_NOT_FOUND": "ABC à rencontré un problème interne - Merci de contacter ACS",
  },
  "register": {
    "title":'Créez votre compte',
    "email":'Adresse e-mail',
    "register":"S'inscrire",
    "emailRequired": 'Saisissez votre adresse e-mail',
    "emailValidity": 'E-mail doit être valide',
    "USER_EXIST":'Cet utilisateur existe déjà',
    "userNotAuthorized": 'ABC à rencontré un problème avec la configuration de votre compte - Merci de contacter ACS',
    "alreadyHaveAccountMessage": 'Vous avez déjà un compte?',
    "signInMessage":'Connectez-vous',
    "confirmationTitle":'Confirmation de création de compte',
    "confirmationMessage":'Votre compte a bien été créé. Vous aller recevoir un email dans quelques instants pour activer votre compte.',
    "EMAIL_NOT_VALID": "E-mail doit être valide",
    "REF_CONTACT_NOT_FOUND": "E-mail est incorrect",
    "CONFLICT_ACCOUNT":"ABC à rencontré un problème avec la configuration de votre compte - Merci de contacter ACS",
    "INTERNAL_ERROR": "ABC à rencontré un problème interne - Merci de contacter ACS"
  },
  "forgottenPassword": {
    "title":'Réinitialisation du mot de passe',
    "email":'Adresse e-mail',
    "forgottenPassword":'Réinitialiser mon mot de passe',
    "emailRequired": 'Saisissez votre adresse e-mail',
    "emailValidity": 'E-mail doit être valide',
    "alreadyHaveAccountMessage": 'Vous avez déjà un compte?',
    "signInMessage":'Connectez-vous',
    "confirmationTitle":'Réinitialisation de votre mot de passe',
    "confirmationMessage":'Votre demande de mise à jour de mot de passe a bien été prise en compte. Vous aller recevoir un email dans quelques instants pour le réinitialiser.',
    "ABC_CONTACT_NOT_FOUND": "E-mail est incorrect",
    "LOCKED_USER": 'ABC à rencontré un problème avec la configuration de votre compte - Merci de contacter ACS',
  },
  "confirmPassword": {
    "title":'Confirmez le mot de passe',
    "email":'Adresse e-mail',
    "emailRequired": 'Saisissez votre adresse e-mail',
    "password":"Mot de passe",
    "passwordRequired": 'Saisissez votre mot de passe',
    "confPassword":'Confirmez le mot de passe',
    "confPasswordRequired": 'Entrez votre mot de passe de confirmation',
    "confirmPassword": 'Confirmez le mot de passe',
    "validationMessage": 'Minimum 8 caractères, 1 majuscule, 1 caractère spécial dans [ @ $ ! % * ? & , ; : ( | ` ^ . ) < > { } / ] et 1 numéro'
  },
  "logoutAlert": {
    "title":'Attention',
    "messageFirstTense": 'N’oubliez pas de fermer tous les onglets de votre navigateur encore ouverts vers des liens ACS.',
    "messageSecondTense":'Ceci vous garantira un fonctionnement optimal lors de votre prochaine connexion à ABC.'
  },
  "subscribeContract": "Souscrire un nouveau contrat",
  "searchContract": "Rechercher un contrat existant",
  "tools": "Applications et ressources",
  "travel": "Voyage",
  "expats": "Expatriés",
  "fac": "Fac International",
  "acs": "ACS",
  "search": "Rechercher",
  "welcome": "Bienvenu(e) dans votre ACS Business Center",
  "logout": "Se déconnecter",
  "news": 'Votre plateforme de souscription évolue pour plus de confort d\'utilisation. Découvrez les changements <a href="https://www.acs-ami.com/files/pdf/divers/new-features-02-2024-fr.pdf" target="_blank">ici</a>.'
}
