<template>
  <v-app>
    <v-app-bar
      app
      flat
      absolute
      color="#154F5F"
      class="text-sm-caption"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center justify-space-between mx-6">
          <div class="box">
            <v-img
              :src="require('@/assets/images/logos/LogoAcs.svg')"
              max-height="50px"
              max-width="124px"
              alt="logo"
              contain
              eager
              class="app-logo me-3"
            />
          </div>
          <div class="box d-none d-sm-flex justify-center welcome-title" >{{ $vuetify.lang.t('$vuetify.welcome') }}</div>
          <div class="box align-center justify-end">
            <div style="color: white;" class="text-caption text-md-body-1">{{ this.user.name }}<br/><a class="social-reason">{{ this.user.broker_name }}</a></div>
            <app-bar-user-menu />
            <app-switcher-locale/>
          </div>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ new Date().getFullYear() }} <a
              href="https://www.acs-ami.com"
              class="text-decoration-none"
              target="_blank"
            >ACS <a class="version">V:{{ this.appConfig.appVersion}}</a></a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mdiMagnify, mdiBellOutline } from '@mdi/js'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AppSwitcherLocale from './components/AppSwitcherLocale.vue'

export default {
  components: {
    AppBarUserMenu,
    AppSwitcherLocale,
  },
  created() {
    this.$vuetify.lang.current = this.$route.params.lang === 'en' ? 'en' : 'fr';
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  data() {
    return {
      user: '',
      
      icons: {
        mdiMagnify,
        mdiBellOutline
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.box {
  flex: 1;
  display: flex;
}
.welcome-title {
  color: #FFFFFF;
  font-size: 16pt;
  font-family: 'Open Sans'; 
  font-weight: 500;
  text-align: center;
}
.version{
  font-size: 10px;
}

.social-reason {
  color: white; 
  font-size: 0.7rem;
}
</style>
