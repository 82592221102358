export default {
  "global":{
    "title": 'ACS Business Center'
  },
  "dataIterator": {
    "rowsPerPageText": "Items per page:",
    "rowsPerPageAll": "All",
    "pageText": "{0}-{1} of {2}",
    "noResultsText": "No matching records found",
    "nextPage": "Next page",
    "prevPage": "Previous page"
  },
  "dataTable": {
    "rowsPerPageText": "Rows per page:"
  },
  "login": {
    "email":"Email",
    "password":"Password",
    "forgetPassword":"Forgot Password?",
    "login":"Login",
    "alertMessage": 'It is possible that you have tabs open and already connected to ABC in your browser. Can you please check and close them before log in.',
    "emailRequired": 'Enter your email',
    "emailValidity": 'Email must be valide',
    "passwordRequired": 'Enter your password',
    "internalError": 'An error has occurred. Please try again later',
    "incorrectMessage": 'The Email and / or password is incorrect',
    "LOCKED_USER": 'ABC has encountered a problem with the configuration of your account - Please contact ACS',
    "USER_NOT_ALLOWED": "ABC has encountered a problem with the configuration of your account - Please contact ACS",
    "REF_CONTACT_NOT_FOUND": "The Email is incorrect",
  },  
  "register": {
    "title":'Create your account',
    "email":'Email',
    "register":'SIGN UP',
    "emailRequired": 'Enter your email',
    "emailValidity": 'Email must be valide',
    "USER_EXIST":'This user already exist',
    "userNotAuthorized": 'ABC has encountered a problem with the configuration of your account - Please contact ACS',
    "alreadyHaveAccountMessage": 'Already have an account?',
    "signInMessage":'Sign in instead',
    "confirmationTitle":'Confirmation creation account',
    "confirmationMessage":'Your account has been created. You will receive an email to activate your account.',
    "EMAIL_NOT_VALID": "Email must be valide",
    "REF_CONTACT_NOT_FOUND": "ABC has encountered a problem with the configuration of your account - Please contact ACS",
    "CONFLICT_ACCOUNT": "ABC has encountered a problem with the configuration of your account - Please contact ACS",
    "INTERNAL_ERROR": "ABC has encountred an internal problem - Please contact ACS"
  },
  "forgottenPassword": {
    "title":'Password reset',
    "email":'Email',
    "forgottenPassword":'Reset my password',
    "emailRequired": 'Enter your email',
    "emailValidity": 'Email must be valide',
    "alreadyHaveAccountMessage": 'Already have an account?',
    "signInMessage":'Sign in',
    "confirmationTitle":'Reset your password',
    "confirmationMessage":'Your password update request has been taken into account. You will receive an email in a few moments to reset it.',
    "ABC_CONTACT_NOT_FOUND": "The Email is incorrect",
    "LOCKED_USER": 'ABC has encountered a problem with the configuration of your account - Please contact ACS',
  },
  "confirmPassword": {
    "title":'Confirm password',
    "email":'Email',
    "emailRequired": 'Enter your email',
    "password":"Password",
    "passwordRequired": 'Enter your password',
    "confPassword":"Confirm password",
    "confPasswordRequired": 'Enter your confirm password',
    "confirmPassword": 'Confirm password',
    "validationMessage": 'Minimum 8 characters, 1 capital letter, 1 special character in [ @ $ ! % * ? & , ; : ( | ` ^ . ) < > { } / ] and 1 number'
  },
  "logoutAlert": {
    "title":'Caution',
    "messageFirstTense": 'Don’t forget to close all tabs in you browser that are still open to ACS links.',
    "messageSecondTense":'This will ensure that your next connection to ABC will run smoothly.'
  },
  "subscribeContract": "Subscribe a new contract",
  "searchContract": "Search an existing contract",
  "tools": "Apps and resources",
  "travel": "Travel",
  "expats": "Expatriates",
  "fac": "Fac International",
  "acs": "ACS",
  "search": "Search",
  "welcome": "Welcome to your ACS Business Center",
  "logout": "Logout",
  "news": 'Our subscription platform evolves for greater user convenience. Discover the latest features <a href="https://www.acs-ami.com/files/pdf/divers/new-features-02-2024-en.pdf" target="_blank">here</a>.'
}
