<template>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card" elevation="5">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7" style="background: transparent">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="require('@/assets/images/logos/LogoACS.png')"
                max-height="50px"
                max-width="80px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>
            </router-link>
          </v-card-title>
  
          <!-- title -->
          <v-card-text>
            <p class="text-1xl font-weight-semibold text--primary mb-2" style="text-align: center">
              {{ $vuetify.lang.t('$vuetify.global.title') }}
            </p>
            <p class="text-2xl font-weight-semibold text--primary mb-2" style="text-align: center">
              {{ $vuetify.lang.t('$vuetify.confirmPassword.title') }}
            </p>
          </v-card-text>
  
          <!-- login form -->
          <v-card-text>
            <v-form @submit="confirmPassword" validate>
              <v-text-field
                v-model="email"
                outlined
                :label="$vuetify.lang.t('$vuetify.confirmPassword.email')"
                placeholder="email@yourdomain.com"
                class="mb-3"
                disabled
                :rules="[v => !!v || $vuetify.lang.t('$vuetify.confirmPassword.emailRequired')]"
              ></v-text-field>

              <v-text-field
                v-model="password"
                outlined
                type="password"
                :label="$vuetify.lang.t('$vuetify.confirmPassword.password')"
                placeholder="password"
                required
                :rules="rules"
              ></v-text-field>

              <v-text-field
                v-model="passwordConfirm"
                outlined
                type="password"
                :label="$vuetify.lang.t('$vuetify.confirmPassword.confPasswordRequired')"
                placeholder="password"
                required
                :rules="[v => !!v || $vuetify.lang.t('$vuetify.confirmPassword.passwordRequired')]"
              ></v-text-field>

              <v-btn
                type="submit"
                block
                color="#3BAFDD"
                class="mt-6 white--text"
                :disabled="isDisabled"
              >
                 {{ $vuetify.lang.t('$vuetify.confirmPassword.confirmPassword') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>

      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="173"
        :src="require(`@/assets/images/misc/mask-light.png`)"
      >

    </div>
  </template>

  <script>
  // eslint-disable-next-line object-curly-newline
  import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
  import { computed } from '@vue/composition-api'
  import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

  export default {
    data () {
      return {
        info: null,
        loading: true,
        errored: false,
        email: this.$route.query.email,
        password: "",
        passwordConfirm: "",
        token: this.$route.params.token,
        reset: this.$route.query.reset,

        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
      }
    },
    setup(props, {root}){
     const rules= computed (()=> [
      value => !!value || 'Password is required',
      value => (value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&,;:(|`^.)<>{}/])[A-Za-z\d@$!%*?&,;:(|`^./)<>{}]{8,}$/.test(value)) || root.$vuetify.lang.t('$vuetify.confirmPassword.validationMessage'),
    ],
    )
    return {
      rules
    }
  },
    validations: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        regex: (value) => value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&,;:(|`^.)<>'{}'])[A-Za-z\d@$!%*?&,;:(|`^.)<>'{}']{8,}$/.test(value)
      },
      passwordConfirm: { required, sameAsPassword: sameAs('password') }
    },
    created() {
      this.submitted = true;
      return this.$v.$touch();
    },
    computed: {
      isDisabled () {
        return this.$v.$invalid;
      }
    },
    methods: {
      async confirmPassword(e) {
        e.preventDefault();
        this.submitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          return false;
        } else {
          try {
            await fetch(this.appConfig.$api_url + "/auth/confirm-password", {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                token: this.token,
                username: this.email,
                password: this.password,
                reset: this.reset
              }),
            });
            this.$router.push("/login");
          } catch (error) {
            console.log("Error when calling confirm password API: " + error)
          }
        }
      },
      submitForm () {
          this.$refs.form.validate();
      },
    },
  }
  </script>

  <style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
  </style>