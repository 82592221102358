<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card" elevation="5">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7" style="background: transparent">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/LogoACS.png')"
              max-height="50px"
              max-width="80px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-1xl font-weight-semibold text--primary mb-2" style="text-align: center">
            ACS Business Center
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit="login" ref="form" v-model="valid" validate>
            <v-text-field
              v-model="email"
              outlined
              :label="$vuetify.lang.t('$vuetify.login.email')"
              placeholder="email@yourdomain.com"
              class="mb-3"
              :rules="emailRules"
              @keyup = "clearAlert"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$vuetify.lang.t('$vuetify.login.password')"
              placeholder="password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" 
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="passwordRules"
              @keyup = "clearAlert"
            ></v-text-field>

            <v-alert  class="alerts" v-if="isAuthorized" type="error">{{ $vuetify.lang.t('$vuetify.login.incorrectMessage') }}</v-alert>
            <a v-for="(code, index) of errorMessageCode" :key="index">
                <v-alert v-if="displayAlert" type="error">{{ $vuetify.lang.t(`$vuetify.login.${code}`) }}</v-alert>
            </a>
            <div v-if="this.existSessionDialog">
              <p class="exist-session-message">{{ $vuetify.lang.t('$vuetify.login.alertMessage') }}</p>
            </div>
            <v-btn
              type="submit"
              block
              color="#3BAFDD"
              class="mt-6 white--text"
              @click="validate"
              :disabled="!valid"
            >
            {{ $vuetify.lang.t('$vuetify.login.login') }}
            </v-btn>
          </v-form>
        </v-card-text>
        
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <v-spacer />
          <!-- reset password  -->
          <router-link :to="{name:'forgotten-password'}">
            {{ $vuetify.lang.t('$vuetify.login.forgetPassword') }}
          </router-link>
        </v-card-text>

      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-light.png`)"
    >

    <template>
          <div class="text-center">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2" style="color: white !important">
                  Annonce
                </v-card-title>
                <v-spacer></v-spacer>

                <v-card-text>
                  <div class="padded">
                    <h4 class="font-weight-medium">
                      {{ $vuetify.lang.t('$vuetify.login.internalError') }}
                    </h4>
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
    </template>
    <template>
          <div class="text-center">
            <v-dialog
              v-model="logoutDialogInfo"
              width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2" style="color: white !important">
                  {{ $vuetify.lang.t('$vuetify.logoutAlert.title') }}
                </v-card-title>
                <v-spacer></v-spacer>

                <v-card-text>
                  <div class="padded">
                    <h4 class="font-weight-medium">
                      {{ $vuetify.lang.t('$vuetify.logoutAlert.messageFirstTense') }}<br/>
                      {{ $vuetify.lang.t('$vuetify.logoutAlert.messageSecondTense') }}
                    </h4>
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="logoutDialogInfo = false"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
    </template>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

import { mapMutations } from "vuex";

export default {
  mounted() {
    this.displaySessionDialog();
    this.displayLogoutDialog();
  },
  data () {
    return {
      dialog: false,
      valid: true,
      existSessionDialog: false,
      displayAlert:false,
      errorMessageCode: [],
      logoutDialogInfo: false,
      responseCode:0,      
    }
  },
  created() {
    this.fetchData();
  },
  setup(props, { root }) {
    
    const isPasswordVisible = ref(false)
    const emailRules = computed(()=>
    [         
        v => !!v || root.$vuetify.lang.t("$vuetify.login.emailRequired"),
        v => /.+@.+\..+/.test(v) || root.$vuetify.lang.t("$vuetify.login.emailValidity")
    ])
    const passwordRules = computed(()=>
     [v => !!v || root.$vuetify.lang.t("$vuetify.login.passwordRequired")])
  
    return {
      isPasswordVisible,
      email: "",
      password: "",
      username: "",
      isAuthorized:false,
      valid: true,
      emailRules,
      passwordRules,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    async login(e) {
      e.preventDefault();
      try {     
        const response = await fetch(this.appConfig.$api_url + "/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: this.email,
            password: this.password,
          }),
        });
        

        if(response.status === 401){
          this.isAuthorized = true;
        } else if (response.status === 400) {
          this.displayAlert = true;
          this.errorMessageCode = [];
          const errorObject = await response.json();
          errorObject.fields.forEach(e=>e.codes.forEach(c=>this.errorMessageCode.push(c.code)));
        } else if (response.status === 200){
          const { user, accessToken } = await response.json();
          this.setUser(user);
          this.setToken(accessToken);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", accessToken);
          this.$router.push("/dashboard/" + user.lang);
        }else {
          this.dialog = true;
        }

      } catch (error) {
        console.log('error when calling auth api ',error)
      } 
    },
    fetchData(){
      if(this.$route.params.lang !== undefined && this.$route.params.lang !== null) {
         this.$vuetify.lang.current = this.$route.params.lang === 'fr' ? 'fr' : 'en';
      }   
    },
    displaySessionDialog(){
        const token = localStorage.getItem("token");
        if(token !== undefined && token !== null){
          this.existSessionDialog = true;
        }
    },

    displayLogoutDialog(){
        const paramsFromURL = window.location.search;
        const urlParams = new URLSearchParams(paramsFromURL);
        const valueFromParam = urlParams.get('fromLogout');
        if(valueFromParam !== undefined && valueFromParam !== null && valueFromParam === 'yes'){
          this.logoutDialogInfo = true;
          this.existSessionDialog = false;
        }
    },
    clearAlert () {
      this.isAuthorized = false;
      this.displayAlert = false;
    },
    validate () {
     this.$refs.form.validate()
    },
    reset () {
     this.$refs.form.reset()
    },
    resetValidation () {
     this.$refs.form.resetValidation()
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.padded {
  padding-top: 20px;
}
.exist-session-message{
  text-align: center;
  color: orange;
  font-size: 14px;
}
.alerts{
  font-size: 14px !important;
}
</style>
