<template>
    <v-card outlined elevation="3" height="100%">
      <v-card-title class="justify-center card-titel" style="color: white !important">
        <v-img
          :src="require('@/assets/images/logos/subscribe.svg')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3 logo"
        ></v-img>
        <span>{{ $vuetify.lang.t('$vuetify.subscribeContract') }}</span>
      </v-card-title>
      <v-spacer/>
      <v-card-subtitle 
        v-if="hasTravelSubscribe" 
       class="card-head full-height pa-2 d-flex justify-space-between card-subtitel">
        <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.travel') }}</div>
      </v-card-subtitle>

      <v-card-text v-if="hasTravelSubscribe" >
        <div class="padded">
          <h4 class="font-weight-medium">
            <ul class="no-style">
              <li v-for="item in data.travel_subscribe" v-bind:key="item.id" class="card-body">
                <a v-if="item.app === 'KASSANDRA' || item.app === 'ESPACE PRO'" @click="onClickLink(item.url)">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </h4>
        </div>
      </v-card-text>
      
      <v-card-subtitle  v-if="hasExpatScoubiSubscribe" class="card-head full-height pa-2 d-flex justify-space-between card-subtitel">
        <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.expats') }}</div>
      </v-card-subtitle>

      <v-card-text v-if="hasExpatScoubiSubscribe">
        <div class="padded">
          <h4 class="font-weight-medium">
            <ul class="no-style">
              <li v-for="item in data.scoubi_expat_subscribe" v-bind:key="item.id" class="card-body">
                <a v-if="item.app === 'SCOUBI'" @click="onClickLinkForSully(item.url)">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </h4>
        </div>
      </v-card-text>

    </v-card>
  </template>
  
  <script>
  import { createHash } from 'crypto';
  
  export default {
    props: {
      data: {
       default: ''
      }
    },
    data() {
      return {
       token: localStorage.getItem("token")
      }
    },
    methods: {
      createUrl:(url)=>url.includes('redirect')?'&token=':'?token=',
      onClickLink(url) {
        this.fetchToken().then(async response => {
            const body = await response.json();
            const token = body.accessToken;
            const cleanURL = url + this.createUrl(url) + token;
            window.open(cleanURL, "_blank")
        })
      },
      onClickLinkForSully(url) {
        this.fetchTokenForSully().then(async response => {
            const body = await response.json();
            const token = body.accessToken;
            const cleanURL = url + this.createUrl(url) + token;
            window.open(cleanURL, "_blank")
        })
      },

      fetchToken() {
        const token = localStorage.getItem("token");
        const fingerprint = window.screen.height +
          window.screen.width +
          window.devicePixelRatio +
          window.navigator.hardwareConcurrency +
          window.navigator.languages +
          window.navigator.userAgent;
        const visitorId = createHash('sha256').update(fingerprint).digest('hex');
        const headers = {
          "Authorization": "Bearer " + token
        };

        return fetch(this.appConfig.$api_url + "/token/generate?visitorId=" + visitorId, { headers })
      },

      fetchTokenForSully() {
        const token = localStorage.getItem("token");
        const fingerprint = window.screen.height +
          window.screen.width +
          window.devicePixelRatio +
          window.navigator.hardwareConcurrency +
          window.navigator.languages +
          window.navigator.userAgent;
        const visitorId = createHash('sha256').update(fingerprint).digest('hex');
        const headers = {
          "Authorization": "Bearer " + token
        };

        return fetch(this.appConfig.$api_url + "/token/sully/generate?visitorId=" + visitorId, { headers })
      }
    },
    computed: {
        hasTravelSubscribe() {
        return this.data.travel_subscribe.length > 0;
        },

        hasExpatScoubiSubscribe() {
        return this.data.scoubi_expat_subscribe.length > 0;
        },
    }
  }
  </script>

<style scoped>
  .padded {
    padding-top: 20px;
  }
  .no-style {
    list-style-type: none;
  }
</style>
  