<template>
  <div>
    <strong v-if="this.appConfig.env !== 'prod'" class="preview_development">Environnement de recette</strong>
    <component :is="resolveLayout">
      <router-view></router-view>
    </component>
</div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  setup() {
    const { route } = useRouter()

    document.title = "ACS Business Center"

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
<style>
  @font-face {
    font-family: "Open Sans";
    src: local("OpenSans"), 
    url(/fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
  }

  .preview_development {
  display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: fixed;
    z-index: 9000;
    background: red;
    color: #ffffff;
    font-size: 1rem;
    width: 300px;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-45deg);
    top: 70px;
    left: -60px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
</style>
