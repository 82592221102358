<template>       
          <v-select 
            class="select"
            dense
            color="#154F5F"
             hide-details
             dark
             outlined
            :items="items"
            :label="$route.params.lang === 'en'?'En':'Fr'"
            solo
            background-color='primary'
            @change="redirect"
            v-model="selectedLang"
          ></v-select>
</template>

<script>
import Vuetify from 'vuetify';
export default {
    created() {
    this.$route    
    this.selectedLang = this.$route.params.lang === "fr" ? "Fr":"En"
    return {
      
    }
  },
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#154F5F',
        },
      },
    }}),
  data: () => ({
      items: ['En', 'Fr'],
      selectedLang: null 
  }),
  methods: {
    redirect(){
        if (this.selectedLang === 'Fr') {
         this.$router.push('/dashboard/fr')
       } else{
         this.$router.push('/dashboard/en')
       }
    }
  }
}
</script>

<style scoped>
.switch{
    margin-left: 25px;
}
.lang{
    color: white;
}
.select{
    max-width: 78px;
    margin-left: 10px !important;
    color: white;
}
</style>