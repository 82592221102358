<template>
    <v-card outlined elevation="3" height="100%">
      <v-card-title class="card-head justify-center card-titel" style="color: white !important">
        <v-img
          :src="require('@/assets/images/logos/apps.svg')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3 logo"
        ></v-img>
        <span>{{ $vuetify.lang.t('$vuetify.tools') }}</span>
      </v-card-title>
      <v-spacer/>
  
      <!-- Voyage -->
      <div v-if="data.travel_allowed">
        <v-card-subtitle class="card-head full-height pa-2 d-flex justify-space-between card-subtitel">
          <div>
            <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.travel') }}</div>
          </div>
        </v-card-subtitle>

        <v-card-text>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="padded">
                <h4 class="font-weight-medium">
                  <ul>
                    <li v-for="item in data.tools_travel" v-bind:key="item.id" class="card-body">
                      <a :href="item.url" target="_blank">{{ item.name }}</a>
                    </li>   
                  </ul>
                </h4>
            </div>
          </div>
        </v-card-text>
      </div>

      <!-- Expatriés -->
      <div v-if="data.expat_allowed">
        <v-card-subtitle class="card-head full-height pa-2 d-flex flex-column card-subtitel">
          <div>
            <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.expats') }}</div>
          </div>
        </v-card-subtitle>

        <v-card-text>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="padded">
                <h4 class="font-weight-medium">
                  <ul>
                    <li v-for="item in data.tools_expat" v-bind:key="item.id" class="card-body">
                      <a :href="item.url" target="_blank">{{ item.name }}</a>
                    </li>   
                  </ul>
                </h4>            
              </div>
          </div>
        </v-card-text>
      </div>

      <!-- Fac International -->
      <div v-if="data.fac">
        <v-card-subtitle class="card-head full-height pa-2 d-flex flex-column card-subtitel">
          <div>
            <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.fac') }}</div>
          </div>
        </v-card-subtitle>
      
        <v-card-text>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="padded">
                <h4 class="font-weight-medium">
                  <ul>
                    <li v-for="item in data.tools_fac" v-bind:key="item.id" class="card-body">
                      <a :href="item.url" target="_blank">{{ item.name }}</a>
                    </li>   
                  </ul>
                </h4>            
              </div>
          </div>
        </v-card-text>
      </div>

      <!-- ACS -->
      <v-card-subtitle class="card-head full-height pa-2 d-flex flex-column card-subtitel">
        <div>
          <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.acs') }}</div>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="padded">
              <h4 class="font-weight-medium">
                <ul>
                  <li v-for="item in data.tools_acs" v-bind:key="item.id" class="card-body">
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                  </li>   
                </ul>
              </h4>            
            </div>
        </div>
      </v-card-text>

    </v-card>
  </template>
  
  <script>
  import { mdiDotsVertical, mdiMenuUp } from '@mdi/js'
  
  export default {
    props: {
      data: {
        default: ''
      }
    },
    setup() {
      return {
        icons: { mdiDotsVertical, mdiMenuUp },
      }
    },
  }
  </script>