<template>
  <v-row>
    <v-col
      cols="12"
      md="4"    
    >
      <dashboard-card-subscription :data=this.dashboard v-if="this.dashboard"></dashboard-card-subscription>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <dashboard-card-contracts :data=this.dashboard v-if="this.dashboard"></dashboard-card-contracts>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <dashboard-card-tools :data=this.dashboard v-if="this.dashboard"></dashboard-card-tools>
    </v-col>
  </v-row>
</template>

<script>
import DashboardCardTools from './DashboardCardTools.vue'
import DashboardCardSubscription from './DashboardCardSubscription.vue'
import DashboardCardContracts from './DashboardCardContracts.vue'

export default {
  components: {
    DashboardCardSubscription,
    DashboardCardContracts,
    DashboardCardTools
  },
  data: function() {
    return {
      dashboard: null
    }
  },
  watch: {
    '$route.params.lang': {
       handler: function() {
           this.fetchData();
       },
       deep: true
   },
  },  
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(){
      this.$vuetify.lang.current = this.$route.params.lang === 'en' ? 'en' : 'fr';
    const token = localStorage.getItem("token");
    const headers = { 
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    };
    fetch(this.appConfig.$api_url + "/dashboard?lang=" + this.$route.params.lang, { headers })
      .then(async response => {
        this.dashboard = await response.json();
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
      })
      .catch(error => {
        console.error("Error when calling dashboard endpoint", error);
      });
    }
  },
}
</script>