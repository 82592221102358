<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        :color="isRefConnected"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar         
         v-bind="attrs"
         v-on="on"
         :size="40"
         color="white">
         <v-icon size="32">{{ icons.mdiAccountOutline }}</v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link class="no-style" :to="'/logout'">
              {{ $vuetify.lang.t('$vuetify.logout') }}
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  setup() {
    const user = localStorage.getItem('user') || '';
    const isRefConnected = JSON.parse(user).refConnected ? 'success' : 'warning';
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      isRefConnected,
    }
  },
  props: {
    data() {
      return {
        isRefConnected: 'success',
        user:''
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
.initials {
  text-transform: uppercase;
}
.no-style {
  text-decoration: none;
}
</style>
