<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card" elevation="5">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7" style="background: transparent">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/LogoACS.png')"
              max-height="50px"
              max-width="80px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-1xl font-weight-semibold text--primary mb-2" style="text-align: center">
            {{ $vuetify.lang.t('$vuetify.global.title') }}
          </p>
          <p class="text-2xl font-weight-semibold text--primary mb-2" style="text-align: center">
            {{ $vuetify.lang.t('$vuetify.register.title') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit="register" ref="form"
                                         v-model="valid"
                                         lazy-validation>
            <v-text-field
              v-model="email"
              outlined
              :label="$vuetify.lang.t('$vuetify.login.email')"
              placeholder="email@yourdomain.com"
              class="mb-3"
              :rules="emailRules"
              required
              @keyup = "clearAlert"
            />
              <a v-for="(code, index) of errorMessageCode" :key="index">
                <v-alert v-if="displayAlert" type="error">{{ $vuetify.lang.t(`$vuetify.register.${code}`) }}</v-alert>
              </a>

            <v-btn
              :disabled="!valid"
              type="submit"
              block
              color="#3BAFDD"
              class="mt-6 white--text"
              @click="validate"
            >
            {{ $vuetify.lang.t('$vuetify.register.register') }}
            </v-btn>
          </v-form>
        </v-card-text>


        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            {{ $vuetify.lang.t('$vuetify.register.alreadyHaveAccountMessage') }}
          </span>
          <router-link :to="{ name:'pages-login' }">
            {{ $vuetify.lang.t('$vuetify.register.signInMessage') }}
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
    
    <template>
      <div class="text-center">
        <v-dialog
          v-model= "dialog"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2" style="color: white !important">
              {{ $vuetify.lang.t('$vuetify.register.confirmationTitle') }}
            </v-card-title>
            <v-spacer></v-spacer>

            <v-card-text>
              <div class="padded">
                <h4 class="font-weight-medium">
                  {{ $vuetify.lang.t('$vuetify.register.confirmationMessage') }}
                </h4>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { computed } from '@vue/composition-api'
export default {
  data() {
    return {
      email: '',
      valid: true,
      dialog: false,
      displayAlert: false,
      registerResponse: 0,
      errorMessageCode: [],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  setup(props, {root}){
    const emailRules = computed(()=>
      [
              v => !!v || root.$vuetify.lang.t("$vuetify.register.emailRequired"),
              v => /.+@.+\..+/.test(v) || root.$vuetify.lang.t("$vuetify.register.emailValidity")
      ])
    return {
      emailRules
    } 
  },
  methods: {
    async register(e) {
      e.preventDefault();
      try {     
        const response = await fetch(this.appConfig.$api_url + "/auth/create-account", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.email
          }),
        });

        if (response.status === 200){
            this.dialog = true
        } else {
            this.dialog = false
        }

        const errorObject = await response.json();
        if (errorObject !== undefined){
          this.errorMessageCode = [];
          this.displayAlert = true;
          errorObject.fields.forEach(e=>e.codes.forEach(c=>this.errorMessageCode.push(c.code)));
        }
        
      } catch (e) {
        console.log("Error when calling register API")
      }
    },
    validate () {
     this.$refs.form.validate()
    },
    reset () {
     this.$refs.form.reset()
    },
    resetValidation () {
     this.$refs.form.resetValidation()
    },
    clearAlert () {
         this.displayAlert = false;
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.padded {
  padding-top: 20px;
}
</style>
