
const my_data = require('../package.json');

const config = {
    env: process.env.VUE_APP_ENV,
    $api_url: process.env.VUE_APP_API_URL,
    kassandraSearchUrl: process.env.VUE_APP_KASSANDRA_SEARCH_URL,
    epSearchUrl: process.env.VUE_APP_EP_SEARCH_URL,
    timeoutDuration: process.env.VUE_APP_TIMEOUT_DURATION,
    appVersion: my_data.version
};

export { config }